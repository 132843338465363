<template>
  <div id="access-request-home" >
      <vs-alert v-if="!loggedIn" color="danger">{{$t('AccessDeniedTitle')}}</vs-alert>
      <template v-else>
        <template v-if="projectAccessRequestLoaded && currentUserCapsOnRequestedProjectLoaded && projectIsValid">
          <vs-alert v-if="!canProjectUsersManage" color="danger">{{$t('cannotManageUsers')}}</vs-alert>
          <div v-else class="text-center">
            <h3 >{{$t('projectAccessRequestDetails')}}</h3>
            <h4 v-if="projectAccessRequest.accepted == '1' && projectAccessRequest.treated == '1'">{{$t('accepted')}}</h4>
            <h4 v-else-if="projectAccessRequest.accepted == '0' && projectAccessRequest.treated == '1'">{{$t('refused')}}</h4>
            <h4 v-else>{{$t('pending')}}</h4>

            <div class="mt-10 grid-layout-container alignment-block">
              <!-- header -->
              <vs-row class="my-10" vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
                <vs-col class="z-10" vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                  <div class="block text-center">
                    <user-avatar v-if="requestUserLoaded" :user="requestUser" :size="`${avatarSize}px`" />
                    <h5>{{requestUser.first_name | capitalize}} {{requestUser.last_name | capitalize}}</h5>
                  </div>
                </vs-col>
                <vs-col class="z-20" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                  <feather-icon v-if="projectAccessRequest.treated == '0'" class="mt-8" icon="ChevronRightIcon" svgClasses="h-16 w-16" />
                  <feather-icon v-if="projectAccessRequest.accepted == '1' && projectAccessRequest.treated == '1'" class="mt-8" icon="CheckIcon" svgClasses="h-16 w-16" />
                  <feather-icon v-if="projectAccessRequest.accepted == '0' && projectAccessRequest.treated == '1'" class="mt-8" icon="XIcon" svgClasses="h-16 w-16" />
                </vs-col>
                <vs-col class="z-0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                  <div class="block text-center" v-if="requestProjectLoaded" >
                    <a target="_blank" :href="`/project/${requestProject.project_slug}`">
                      <vs-avatar :size="`${avatarSize}px`" :src="`${this.$appConfig.imgBaseUrl}/project/cover/${requestProject.project_id}/${thumbName}`"/>
                      <h5>{{decodeURIComponent(requestProject.project_nicename)}}</h5>
                    </a>
                  </div>
                </vs-col>
              </vs-row>

              <vs-alert v-if="this.projectAccessRequest.closed == '1'" color="danger">{{$t('RequestClosed')}}</vs-alert>

              <template v-if="this.projectAccessRequest.closed != '1'">
                <!-- boutons -->
                <div v-if="projectAccessRequest.treated == '0'" class="mt-20 flex flex-wrap text-center">
                  <vs-button @click.stop="acceptRequest(false)" class="mx-5 p-6 w-1/4 ml-auto text-center" size="large" color="danger" type="filled">{{$t('refuse')}}</vs-button>
                  <vs-button @click.stop="acceptRequest(true)" class="mx-5 p-6 w-1/4  mr-auto text-center" size="large" color="primary" type="filled">{{$t('accept')}}</vs-button>
                </div>              

                <template v-if="projectAccessRequest.treated == '1'">
                  <div v-if="projectAccessRequest.accepted == '1'">
                    <vs-alert class="mt-4" color="warning"><feather-icon icon="AlertTriangleIcon" svgClasses="h-4 w-4 mr-5" />
                      {{$t('pleaseAdjustUserRights')}}
                    </vs-alert>
                    <!-- caps -->
                    <vs-divider class="mt-10"></vs-divider>
                    <div class="flex flex-wrap">
                      <div class="w-1/3 ml-auto text-center">
                        <!-- can_project_access_start_date -->
                        <p>{{$t('can_project_access_start_date')}}</p>
                        <input type="date" v-model="userDates.can_project_access_start_date" 
                          @change="set_ProjectUserCap(requestUser.user_id, 'can_project_access_start_date', $event)">
                      </div>
                      <div class="w-1/3 mr-auto text-center">
                        <p>{{$t('can_project_access_end_date')}}</p>
                        <input type="date" v-model="userDates.can_project_access_end_date" class="input_date" @change="set_ProjectUserCap(requestUser.user_id, 'can_project_access_end_date', $event)">
                      </div>
                    </div>                
                    <vs-divider class="mt-10"></vs-divider>
                    <!-- Capacités -->
                    <div v-for="cap in userCaps" :key="cap.name" class="block w-full">
                      <div class="flex w-full my-4">
                        <p class="w-1/2 mr-auto text-right mr-5">{{$t(cap.name)}}</p>
                        <div class="w-1/2 text-left ml-5 flex" >
                          <vs-checkbox :disabled="cap.disabled" class="flex" icon-pack="feather" v-model="cap.value" :color="cap.color" :icon="cap.icon" 
                            @change="set_ProjectUserCap(requestUser.user_id, cap.name, $event)"> </vs-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="block w-1/2 ml-auto mr-auto">
                    <vs-button @click.stop="closeRequest()" class="mt-4 p-6 w-full ml-auto text-center" size="large" color="primary" type="filled">{{$t('closeRequest')}}</vs-button>
                    <!-- cancel -->
                    <vs-button @click.stop="restartRequest()" class="mt-6 p-6 w-full ml-auto mr-auto text-center" color="warning" type="flat">{{$t('Cancel')}}</vs-button>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </template>
  </div>
</template>
<script>
import axios from '@/axios.js'
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  components: {
    logActivity
  },
  data () {
    return {
      // ROYBON / Information sur la requete
      request_hash: this.$route.params.request_hash,
      projectAccessRequest: {},
      projectAccessRequestLoaded: false,
      requestUser: {},
      requestUserLoaded: {},
      requestProject: {},
      requestProjectLoaded: {},

      // ROYBON / Capacités du user demandeur
      userDates: {
        can_project_access_start_date: null,
        can_project_access_end_date: null
      },
      userCaps: {
        can_project_access: { 
          name: 'can_project_access',
          value: false,
          icon: 'icon-unlock', 
          color: 'primary', 
          disabled: true
        },
        can_project_model_see: { 
          name: 'can_project_model_see',
          value: false,
          icon: 'icon-eye', 
          color: 'primary', 
          disabled: false
        },
        can_project_tools_use: { 
          name: 'can_project_tools_use',
          value: false,
          icon: 'icon-activity', 
          color: 'primary', 
          disabled: false
        },
        can_project_tags_see: { 
          name: 'can_project_tags_see',
          value: false,
          icon: 'icon-eye', 
          color: 'primary', 
          disabled: false
        },
        can_project_users_see: { 
          name: 'can_project_users_see',
          value: false,
          icon: 'icon-user-check', 
          color: 'primary', 
          disabled: false
        },
        can_project_tags_add: { 
          name: 'can_project_tags_add',
          value: false,
          icon: 'icon-tag', 
          color: 'warning', 
          disabled: false
        },
        can_project_shares_send: { 
          name: 'can_project_shares_send',
          value: false,
          icon: 'icon-share-2', 
          color: 'warning', 
          disabled: false
        }
      },

      // ROYBON / Image du projet
      avatarSize: 100,
      thumbName: 'thumb_500_500.jpg',

      // ROYBON / Droits de l'utilisateur actuel sur le projet demandé
      currentUserCapsOnRequestedProject: {},
      currentUserCapsOnRequestedProjectLoaded: false,

      // ROYBON / Droits du demandeur sur le projet
      requestUserCapsOnRequestedProject: {},
      requestUserCapsOnRequestedProjectLoaded: false,

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getProjectAccessRequestByHash: 'getProjectAccessRequestByHash',
      getUserProjectCaps: 'getUserProjectCaps',
      getUserInfos: 'getUserInfos',
      getProjectInfos: 'getProjectInfos',
      setProjectAccessRequestState: 'setProjectAccessRequestState',
      setProjectUserCap: 'setProjectUserCap'
    }
  },
  methods: {    
    // ROYBON / Recommence le traitement de la demande
    restartRequest () {
      this.setRequestStatus('treated', 0)
      this.setRequestStatus('accepted', 0)
      this.setRequestStatus('closed', 0)
      this.projectAccessRequest.treated = '0'
      this.projectAccessRequest.accepted = '0'
      this.projectAccessRequest.closed = '0'
    },
    // ROYBON / Acceptation de la demande
    closeRequest () {
      this.setRequestStatus('closed', 1)
      this.projectAccessRequest.closed = '1'
      logActivity.add('project_access_request_closed', this.request_hash)
    },
    // ROYBON / Acceptation de la demande
    acceptRequest (decision) {
      // refus
      if (decision == false) {
        this.setRequestStatus('treated', 1)
        this.setRequestStatus('accepted', 0)
        this.setRequestStatus('closed', 0)
        this.projectAccessRequest.treated = '1'
        this.projectAccessRequest.accepted = '0'
        this.$vs.notify({
          color:'danger',
          title:this.$t('RejectedRequestAccessButton')
        })        
        logActivity.add('project_access_request_refusal', this.request_hash)
        logActivity.add('project_access_request_refused', this.request_hash, this.requestUser.user_id, this.requestProject.project_id)
      // acceptation
      } else if (decision == true) {
        this.set_ProjectUserCap(this.requestUser.user_id, 'can_project_access', '1')
        this.userCaps.can_project_access.value = true
        this.setRequestStatus('treated', 1)
        this.setRequestStatus('accepted', 1)
        this.setRequestStatus('closed', 0)
        this.projectAccessRequest.treated = '1'
        this.projectAccessRequest.accepted = '1'
        this.$vs.notify({
          color:'success',
          title:this.$t('AcceptedRequestAccessButton')
        })
        this.$vs.notify({
          color:'warning',
          title:this.$t('pleaseAdjustUserRights')
        })
        logActivity.add('project_access_request_acceptation', this.request_hash)
        logActivity.add('project_access_request_accepted', this.request_hash, this.requestUser.user_id, this.requestProject.project_id)
      }
    },
    // ROYBON / Définit le statut de la validation de la requête
    setRequestStatus (parameter, value) {
      //const params = `request_hash=${this.request_hash}&parameter=${parameter}&value=${value}`
      const params = new FormData()
      params.set('request_hash', `${this.request_hash}`)
      params.set('parameter', `${parameter}`)
      params.set('value', `${value}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.setProjectAccessRequestState}`
      axios.post(rimnat_api_call_url, params)
        .then(() => { 
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Obtient les infos sur la requête
    getAccessRequestInfos () {
      const params = `request_hash=${this.request_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectAccessRequestByHash}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.projectAccessRequest = response.data
          this.projectAccessRequestLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Vérifie si le current user peut administrer les users sur ce projet
    getCurrentUserCapsOnRequestedProject () {
      const user_hash = md5(sha1(this.$store.state.AppActiveUser.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${user_hash}&project_id=${this.projectAccessRequest.project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserProjectCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data != null && response.data != 'null') this.currentUserCapsOnRequestedProject = response.data[0]
          this.currentUserCapsOnRequestedProjectLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Récupère les droits de l'utilisateur demandeur sur le projet
    getRequestUserCapsOnRequestedProject () {
      const user_hash = md5(sha1(this.requestUser.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `user_id=${this.requestUser.user_id}&user_hash=${user_hash}&project_id=${this.projectAccessRequest.project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserProjectCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data != null && response.data != 'null') this.requestUserCapsOnRequestedProject = response.data[0]
          this.requestUserCapsOnRequestedProjectLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Obtient les informations sur l'utilsateur à l'origine de la demande
    getRequestUser () {
      const params = `user_id=${this.projectAccessRequest.user_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserInfos}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data != null && response.data != 'null') this.requestUser = response.data
          this.requestUserLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Obtient les informations sur le projet
    getRequestProject () {
      const params = `project_id=${this.projectAccessRequest.project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectInfos}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => {
          if (response.data != null && response.data != 'null') this.requestProject = response.data
          this.requestProjectLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Application d'un changement de paramètre sur le user
    set_ProjectUserCap  (user_id, parameter, event) {
      let value = ''
      // Interprète la valeur
      if (typeof event === 'object') {
        if (event.target.type === 'checkbox') {
          value = event.target.checked ? '1' : '0' 
        } else if (event.target.type === 'date') {
          value = event.target.value ? event.target.value : 'null' 
        }
      } else if (typeof event === 'string') {
        value = event
      } else if (event === '') {
        value = 'null'
      }
 
      if (value) {
        // sauvegarde de la nouvelle valeur de paramètre
        let params = ''
        let rimnat_api_call_url = ''
        const urlEncodedValue = encodeURIComponent(value)
        //params = `user_id=${user_id}&portal_id=${this.projectAccessRequest.portal_id}&project_id=${this.requestProject.project_id}&parameter=${parameter}&value=${urlEncodedValue}`
        params = new FormData()
        params.set('user_id', `${user_id}`)
        params.set('portal_id', `${this.projectAccessRequest.portal_id}`)
        params.set('project_id', `${this.requestProject.project_id}`)
        params.set('parameter', `${parameter}`)
        params.set('value', `${urlEncodedValue}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.setProjectUserCap}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_user_cap_update', parameter, null, null, this.requestProject.project_id)
              logActivity.add('project_user_cap_updated', parameter, user_id, null, this.requestProject.project_id)
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
            } else {
              // error
              if (event !== '') {
                if (event.target.type === 'checkbox') {
                  event.target.checked = !event.target.checked
                }
              }
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          })
      }
    }
  },
 
  computed: {
    loggedIn () {
      return this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.user_id) !== '0'
    }, 
    projectIsValid () {
      let returned = false
      if (this.projectAccessRequestLoaded) {
        if (typeof this.projectAccessRequest != 'undefined' && this.projectAccessRequest != null) {
          returned = !isNaN(this.projectAccessRequest.project_id)
        }
      }
      return returned
    },
    canProjectUsersManage () {
      let returned = false
      const gc =  this.$store.state.current_user_global_caps
      const poc = this.$store.state.current_user_portal_caps
      if (gc != null && typeof gc.can_global_manage != 'undefined' && gc.can_global_manage == '1') {
        returned = true
      } else if (poc != null && typeof poc.can_portal_users_manage != 'undefined' && poc.can_portal_users_manage == '1') {
        returned = true
      } else if (this.currentUserCapsOnRequestedProjectLoaded) {
        if (typeof this.currentUserCapsOnRequestedProject != 'undefined' && this.currentUserCapsOnRequestedProject != null) {
          returned = this.currentUserCapsOnRequestedProject.can_project_users_manage == '1'
        }
      }
      return returned
    }
  },

  beforeMount () {
  },

  created () {
  },
  mounted () {
    this.getAccessRequestInfos()
  },

  watch: {
    projectAccessRequestLoaded: function () { 
      if (this.projectAccessRequestLoaded && this.projectAccessRequest != null && typeof this.projectAccessRequest.user_id != 'undefined' && typeof this.$store.state.AppActiveUser.user_id != 'undefined') {
        this.getCurrentUserCapsOnRequestedProject()
        this.getRequestUser()
        this.getRequestProject()
        // add (type, result, user_id = null, portal_id = null, project_id = null, layer_id = null, session_id = null) {
        logActivity.add('project_access_request_manager', this.request_hash)
      }
    },
    requestUser: function () { 
      if (this.requestUserLoaded && typeof this.requestUser.user_id != 'undefined') {
        if (!isNaN(this.requestUser.user_id)) this.getRequestUserCapsOnRequestedProject()
      }
    },
    requestUserCapsOnRequestedProject: function () { 
      if (this.requestUserCapsOnRequestedProjectLoaded && this.requestUserCapsOnRequestedProject != null && typeof this.requestUserCapsOnRequestedProject.can_project_access != 'undefined') {
        // dates
        if (this.requestUserCapsOnRequestedProject.can_project_access_start_date != null) this.userDates.can_project_access_start_date = this.requestUserCapsOnRequestedProject.can_project_access_start_date
        if (this.requestUserCapsOnRequestedProject.can_project_access_end_date != null) this.userDates.can_project_access_end_date = this.requestUserCapsOnRequestedProject.can_project_access_end_date
        // caps
        if (this.requestUserCapsOnRequestedProject.can_project_access == '1') this.userCaps.can_project_access.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_model_see == '1') this.userCaps.can_project_model_see.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_tags_see == '1') this.userCaps.can_project_tags_see.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_tools_use == '1') this.userCaps.can_project_tools_use.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_users_see == '1') this.userCaps.can_project_users_see.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_tags_add == '1') this.userCaps.can_project_tags_add.value = true
        if (this.requestUserCapsOnRequestedProject.can_project_shares_send == '1') this.userCaps.can_project_shares_send.value = true
      }
    }
  }
}

</script>
<style lang="scss">
</style>
